import Router from 'vue-router'
import Vue from 'vue'
import layout from "@/components/layout.vue";
// import index from '@/App'
Vue.use(Router)
export default new Router({
  routes: [
    {
      path: "/login",
      component: () => import('@/views/login.vue'),
      name: "login",
      hidden: true,
    },
    {
      path: '',
      component: layout,
      redirect: "/emailAddressLibrary",
      children: [
        {
          path: "/emailAddressLibrary",
          component: () => import('@/views/emailAddressLibrary.vue'),
          meta:{
            title:'达人邮箱地址库'
          }
          
        },
        {
          path:'/contentTemplate',
          component:() => import('@/views/contentTemplate.vue'),
          meta:{
             title:"邮件模板"
          }
        },

        {
          path:'/DocManage',
          component:() => import('@/views/DocManage.vue'),
          meta:{
            title:'达人库文件管理'
          }
          
        },

        {
          path:'/sendLog',
          component:() => import('@/views/sendLog.vue'),
          meta:{
            title:"邮件发送日志",
          }
          
        },

        {
          path:'/darrenSquare',
          component:() => import('@/views/DarrenSquare'),

          meta:{
            title:'达人广场--建联2.2亿TikTok达人'
          }
        },
        {
          path:'/sendEmail',
          component:() => import('@/views/sendEmail'),

          meta:{
            title:'发件人邮箱管理'
          }
        },
        
        {
          path:'/DataWatchdog',
          component:() => import('@/views/DataWatchdog'),

          meta:{
            title:'数据看板'
          }
        },

        {
          path:'/AccountManagement',
          component:() => import('@/views/AccountManagement'),

          meta:{
            title:'账号管理'
          }
        },
        //productExport
        {
          path:'/productExport',
          component:() => import('@/views/productExport'),

          meta:{
            title:'商品导出'
          }
        },
        {
          path:'/home',
          component:() => import('@/views/homeView.vue'),
          title:"账号管理"
        }
      ],
    },
  ],
})


