<template>
  <div class="out_menu">
    <img class="top_right_img" src="../assets/top_right.png" alt="">
    <img class="btom_left_img" src="../assets/botm_left.png" alt="">
    <div class="top_img">
        <img src="../assets/big_logo.png" alt="">
    </div>
    <el-menu
      :default-active="activeMenu"
      class="el-menu-vertical-demo changeHover"
      background-color="#0671FC"
      text-color="#fff"
      v-for="(item,indexs) in menuList" :key="indexs"
      active-text-color="#fff"
      @select="changeItem"
    >
    <el-submenu index="1" v-if="item.child">
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="(items,indexs) in item.child" :key="indexs" :index="items.path" :class="activeMenu == items.path ? 'orange':''">
            {{ items.name }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item :index="item.path" :class="activeMenu == item.path ? 'orange':''"  v-if="!item.child">
        <span slot="title">
            {{item.name}}
          </span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "myAsides",
  data(){
    return{
      activeMenu:'/emailAddressLibrary',
      menuList : [],
      login_info:'',
    }
  },
  created(){
      this.login_info=JSON.parse( localStorage.getItem('login_info'));
      if (this.login_info.type == 1) {
        this.menuList = [
          {
            name: "批量邮件管理", child: [
              {name: "达人邮箱地址库", path: '/emailAddressLibrary'},
              {name: "发件内容模板", path: '/contentTemplate'},
              {name: "达人库文件管理", path: '/DocManage'},
              {name: "邮件发送日志", path: '/sendLog'},
              {name: "发件人邮箱管理", path: '/sendEmail'},
            ],

          },
          {
            name: "总管理页面", child: [
              {name: "数据看板", path: '/DataWatchdog'},
              {name: "账号管理", path: '/AccountManagement'},
              {name: "商品导出", path: '/productExport'},
            ],
          }
        ]
      } else {
        this.menuList = [
          {
            name: "批量邮件管理", child: [
              {name: "达人邮箱地址库", path: '/emailAddressLibrary'},
              {name: "发件内容模板", path: '/contentTemplate'},
              {name: "达人库文件管理", path: '/DocManage'},
              {name: "邮件发送日志", path: '/sendLog'},
              {name: "发件人邮箱管理", path: '/sendEmail'},
            ],

          },
          // {
          //   name: "总管理页面", child: [
          //     {name: "商品导出", path: '/productExport'},
          //   ],
          // }
        ]
      }
      this.activeMenu = this.$route.path
  },
  methods:{
    changeItem(index){
      console.log(this.activeMenu,'activeMenu');
      if(this.activeMenu != index){
        this.$router.push(index)
      }
     this.activeMenu = index
    }
  }
}
</script>

<style scoped lang="less">
.out_menu{
  position: relative;
  height: calc(100% - 225px);
  .top_right_img{
    position: absolute;
    top: -24px;
    right: 0;
    width: 90px;
    z-index: 99;
  }
  .btom_left_img{
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 90px;
  }
}
.top_img{
  // height: 80px;
  margin: 24px;
  img{
    width: 100%;
  }

}
.el-menu{
  border-right: 0!important;
}
.el-menu-item:focus, .el-menu-item:hover {
  background-color: #005CD4!important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
}
.el-menu-item:active{
  background-color: #005CD4!important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
}
.el-submenu .el-menu-item{
  height: 40px!important;
  line-height: 40px!important;
  min-width: calc(100% - 8px)!important;
}
.el-menu-item, .el-submenu__title{
  height: 40px!important;
  line-height: 40px!important;
  min-width: calc(100% - 8px)!important;
}
.el-menu-item, .el-submenu__title{
  height: 40px!important;
  line-height: 40px!important;
  min-width: calc(100% - 8px)!important;
}
/deep/.el-menu-item-group__title{
  display: none!important;
  padding: 0!important;

}
/deep/.el-submenu__title{
  height: 40px!important;
  line-height: 40px!important;
  min-width: calc(100% - 8px)!important;
}
/deep/.el-submenu__title:focus {
  background-color: #005CD4!important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
}
/deep/ .el-submenu__title:hover{
  background-color: #005CD4!important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
}
/deep/.el-submenu__title:active{
  background-color: #005CD4!important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
}
/deep/.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
  display: none !important;
}
.orange{
  background-color: #005CD4 !important;
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
  width: calc(100% - 5px);
  border-left: 2px solid #fff !important;
}
</style>
