<template>
  <div>
    <el-container style="height: 100vh;">
      <el-aside width="200px" style="background-color: #0671FC" class="top_asides">
       <myAsides></myAsides>
      </el-aside>
      <el-container>
        <el-header  style="background-color: #F5F6FB" class="change">
          <myHeader></myHeader>
        </el-header>
        <el-main class="scoll_box">
         <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import myAsides from "@/components/myAsides";
import myHeader from "@/components/myHeader";

export default {
   /* eslint-disable */
  name: "layout_box",
  components:{
    myAsides,myHeader
  },
  beforeCreate(){

  }
}
</script>

<style scoped lang="less">
.el-main{
  padding: 0!important;
  background-color: #F5F6FB;
}
.top_asides{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .change{
    padding: 16px 24px 16px;
    height: auto !important;
  }
</style>
