<template>
  <div class="header_box">
    <!-- 达人广场--建联2.2亿TikTok达人 -->
     <div>
      {{ title }}
     </div>
     <div>
      <el-dropdown trigger="click" placement="bottom" @command="commandHandle"  @visible-change="oneArrow = !oneArrow">
        <div style="display:flex">
          <div class="header_img" style="display: inline-block">
        <span class="el-dropdown-link">
          <img src="../assets/avar.png" alt="">
        </span>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">退出登录</el-dropdown-item>
        </el-dropdown-menu>

     </div>
          <span class="el-dropdown-link" style="margin-left: 17px;">
          <i class="el-icon-arrow-down el-icon--right" v-if="!oneArrow" style="vertical-align: sub;color: #D8D8D8;"></i>
          <i class="el-icon-arrow-up el-icon--right" v-if="oneArrow" style="vertical-align: sub;color: #D8D8D8;"></i>
        </span>
        </div>


    </el-dropdown>
     </div>

  </div>
</template>

<script>

import Eventbus from "@/axios/eventbus";

export default {
  /* eslint-disable */
  name: "myHeader",
  data(){
    return{
      oneArrow:false,
      title:'',
    }
  },
    //监听路由的变化
    watch:{
    $route:{
      handler(val,oldval){
        // console.log(val);//新路由信息
        // console.log(oldval);//老路由信息
        this.title = val.meta.title
        console.log(this.title,'val.meta.title',val);
      },
      // 深度观察监听
      deep: true
    },
    showSelect:{
      deep:true,
     handler(){

     }
    }
  },
  created(){
    this.title = this.$route.meta.title
    Eventbus.$on('changeAccountTitle',(v)=>{
      if (v == 1){
        this.title = '账号管理'
      }else {
        this.title = '邮箱管理'
      }

    })
  },
  methods:{
    commandHandle(val){
      if(val == 1){
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped lang="less">
  .el-header{
    background-color: #ffffff;
  }
  .header_box{
    font-size: 16px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    .header_img{
      width: 48px;
      height: 48px;
      background-color: #0671FC;
      border-radius: 12px;
      text-align: center;
      img{
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
    /* margin-top: 32px; */
  }
</style>
