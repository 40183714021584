import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import './assets/global.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css'
Vue.use(ElementUI);
import {
  message
} from '@/assets/resetMessage';

// Vue.config.productionTip = false

import 'rapidi-ui/dist/css/index.scss';
import RAUI from 'rapidi-ui';


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
Vue.prototype.$message = message;
Vue.use(RAUI)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
